<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row mt-5">
      <div class="col-sm-6">
        <div>
          <router-link
            :to="`/importazioni`"
            class="btn btn-light text-gray-700 fs-6 text-start"
          >
            <i class="bi bi-arrow-left fs-6 text-gray-700"></i> Lista
            importazioni
          </router-link>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          tesserati di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        importazioni_dettaglio_list.length === 0 &&
        loaded &&
        (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="importazioni_dettaglio_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        :pushTo="`/persone/dettaglio-persona/anagrafica-persona/`"
        nameIdToPush="id_persona"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsDettaglioImportazione"
        @current-change="setCurrentPageDettaglioImportazione"
      >
        <template v-slot:cell-cognome="{ row: data }"
          ><div class="tab-long">
            {{ data.cognome }} {{ data.nome }} -
            {{ data.data_nascita }}
          </div></template
        >
        <template v-slot:cell-cod_fisc="{ row: data }">{{
          data.cod_fisc
        }}</template>
        <template v-slot:cell-sesso="{ row: data }">{{ data.sesso }}</template>
        <template v-slot:cell-disciplina="{ row: data }">{{
          data.disciplina
        }}</template>
        <template v-slot:cell-esito_note="{ row: data }"
          ><div
            v-if="data.id_esito == 4"
            class="text-danger"
            v-html="data.note_esito"
          ></div>
          <div
            v-if="data.id_esito != 4"
            :class="
              data.id_esito == 2
                ? 'text-primary'
                : data.id_esito == 1
                ? 'text-warning'
                : data.id_esito == 3
                ? 'text-success'
                : ''
            "
          >
            {{ data.esito }}
          </div></template
        >
        <template v-slot:cell-options>
          <div class="btn-group">
            <i class="bi bi-eye text-dark fs-4"></i>
          </div>
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed } from "vue";
import Datatable from "../../kt-datatable/KTDatatable.vue";
import Loading from "vue3-loading-overlay";
import isEnabled from "@/composables/isEnabled.js";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  name: "table-dettaglio-importazione",
  components: { Datatable, Loading },
  emits: ["getDettaglioImportazione", "resetFilters"],
  props: {},
  setup(props, { emit }) {
    const store = useStore();

    const setFetchRowsDettaglioImportazione = (e) => {
      store.commit("setFetchRowsDettaglioImportazione", e);
      emit("getDettaglioImportazione");
    };
    const setCurrentPageDettaglioImportazione = (page) => {
      store.commit("setCurrentPageDettaglioImportazione", page);
      emit("getDettaglioImportazione");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnDettaglioImportazione", columnName);
      store.commit("setSortOrderDettaglioImportazione", order);
      emit("getDettaglioImportazione");
    };

    const isLoading = ref(false);

    /* const stampaTessera = async (id) => {
      isLoading.value = true;
      await stampaTesseraSingola(id).then((res) => {
        isLoading.value = false;
        if (res.status == 200) {

        } else {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        }
      });
    }; */

    const tableHeader = ref([
      {
        name: "Tesserato",
        key: "cognome",
        sortable: false,
      },
      {
        name: "Cod. fiscale",
        key: "cod_fisc",
        sortable: false,
      },
      {
        name: "Sesso",
        key: "sesso",
        sortable: false,
      },
      {
        name: "Disciplina",
        key: "disciplina",
        sortable: false,
      },
      {
        name: "Esito",
        key: "esito_note",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    return {
      importazioni_dettaglio_list: computed(() =>
        store.getters.getStateFromName("resultsimportazioni_dettaglio_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedimportazioni_dettaglio_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordimportazioni_dettaglio_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusimportazioni_dettaglio_list")
      ),

      currentPage: computed(
        () => store.getters.currentPageDettaglioImportazione
      ),
      rowsToSkip: computed(() => store.getters.rowsToSkipDettaglioImportazione),
      fetchRows: computed(() => store.getters.fetchRowsDettaglioImportazione),
      sortColumn: computed(() => store.getters.sortColumnDettaglioImportazione),
      sortOrder: computed(() => store.getters.sortOrderDettaglioImportazione),
      setFetchRowsDettaglioImportazione,
      setCurrentPageDettaglioImportazione,
      setSortOrderColumn,
      isEnabled,
      tableHeader,
      tutteStagioniTesserati: computed(
        () => store.getters.tutteStagioniTesserati
      ),
      //stampaTessera,
      isLoading,
    };
  },
};
</script>

<style scoped></style>
