<template>
  <div>
    <div class="card shadow">
      <div
        class="pt-4 pb-4 pe-4 ps-4"
        @keyup.enter="$emit('getDettaglioImportazione')"
      >
        <div class="row">
          <div class="col-md-4">
            <SelectInput
              :options="richieste_importazioni_stati"
              name="statoImportazioniLookup"
              placeholder="Stato"
              :value="statoDettaglioImportazione"
              @changeSelect="setStatoDettaglioImportazione($event)"
            />
          </div>
          <div class="text-end col-md-8">
            <button
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="$emit('getDettaglioImportazione')"
              :disabled="!loaded"
            >
              Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
            </button>
            <span
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="
                $emit('resetFilters');
                setStatoDettaglioImportazione(null);
              "
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

export default defineComponent({
  name: "filtri-dettaglio-importazione",
  emits: ["resetFilters", "getDettaglioImportazione"],
  props: {
    resetted: {
      type: Boolean,
    },
  },
  components: {
    SelectInput,
  },
  setup() {
    const isActive = ref(false);
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const richieste_importazioni_stati = computed(() =>
      store.getters.getStateFromName("richieste_importazioni_stati")
    );
    const keys = ref("is");
    if (!richieste_importazioni_stati.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const statoDettaglioImportazione = computed(
      () => store.getters.statoDettaglioImportazione
    );
    const setStatoDettaglioImportazione = (event) => {
      store.commit("setStatoDettaglioImportazione", event);
    };

    return {
      isActive,
      richieste_importazioni_stati,
      setStatoDettaglioImportazione,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedimportazioni_dettaglio_list")
      ),
      statoDettaglioImportazione,
    };
  },
});
</script>

<style></style>
