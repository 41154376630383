<template>
  <div>
    <FiltriDettaglioImportazione
      @getDettaglioImportazione="searchDettaglioImportazioneList"
      @resetFilters="resetFilters"
    />
    <TableDettaglioImportazione
      @getDettaglioImportazione="getDettaglioImportazione"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  onUnmounted,
} from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import FiltriDettaglioImportazione from "@/components/components-fit/importazioni/FiltriDettaglioImportazione.vue";
import TableDettaglioImportazione from "@/components/components-fit/importazioni/TableDettaglioImportazione.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "dettaglio-importazione",
  components: {
    FiltriDettaglioImportazione,
    TableDettaglioImportazione,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Dettaglio importazione", []);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const rowsToSkip = computed(
      () => store.getters.rowsToSkipDettaglioImportazione
    );
    const fetchRows = computed(
      () => store.getters.fetchRowsDettaglioImportazione
    );
    const id_esito = computed(() => store.getters.statoDettaglioImportazione);
    /*  const sortColumn = computed(
      () => store.getters.sortColumnDettaglioImportazione
    );
    const sortOrder = computed(
      () => store.getters.sortOrderDettaglioImportazione
    ); */

    const route = useRoute();

    const getDettaglioImportazione = () => {
      store.dispatch("setStoreListData", {
        keys: {
          guid_richiesta: route.params.guid,
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          id_esito: id_esito.value,
        },
        apiLink: globalApi.IMPORTAZIONI_DETTAGLIO_LIST,
        itemName: "importazioni_dettaglio_list",
      });
    };

    const resetFilters = () => {
      store.commit("resetFiltersDettaglioImportazione");
      getDettaglioImportazione();
    };
    getDettaglioImportazione();

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedimportazioni_dettaglio_list")
    );

    const searchDettaglioImportazioneList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipDettaglioImportazione");
      getDettaglioImportazione();
    };

    onUnmounted(() => store.commit("setRowsToSkipDettaglioImportazione"));

    return {
      getDettaglioImportazione,
      resetFilters,
      searchDettaglioImportazioneList,
    };
  },
});
</script>
